'use strict';

angular.module('cpformplastApp.jobs')
    .factory('Subjob', function(Util, Job) {

    class Subjob {
        constructor({id, index, title, date, quantity, hoursAmount, job, assignedQuantity, remainingQuantity, poundsNeeded, skid, remainingPoundsNeeded, notes}) {
          this.id = id;
          this.index = index;
          this.title = title;
          this.notes = notes;
          this.date = new Date(date);
          this.hoursAmount = Math.ceil(hoursAmount);
          this.quantity = Math.ceil(quantity);
          this.assignedQuantity = Math.ceil(assignedQuantity);
          this.remainingQuantity = Math.ceil(remainingQuantity);
          this.poundsNeeded = Math.ceil(poundsNeeded);
          this.remainingPoundsNeeded = Math.ceil(remainingPoundsNeeded);
          this.job = new Job(job);
          this.skid = skid;
        }

        getValueFromKey(key) {
          switch(key) {
            case 'job':
              return this.title;
            case 'mold':
              return this.job.item.mold;
            case 'setup':
              return this.job.item.setup;
            case 'client':
              return this.job.item.client.name;
            case 'item':
              return this.job.item.name;
            case 'ups':
              return this.job.item.ups;
            case 'material':
              return this.job.item.category.dimension + ' ' + this.job.item.category.name ;
            case 'remainingQuantity':
              return this.remainingQuantity;
            case 'deliveryDate':
              return this.date;
            case 'sheetsPerHour':
              return this.job.item.sheetsPerHour;
            case 'salesman':
              return this.job.item.client.salesman.name;
            case 'materialDeliveryDate':
              return this.job.materialDeliveryDate;
            default:
              return '';
          }
        }

        get tableStruct() {
          return {
            id: this.id,
            job: this.job,
            title: this.title,
            mold: this.job.item.mold,
            setup: this.job.item.setup,
            client: this.job.item.client.name,
            item: this.job.item.name,
            ups: this.job.item.ups,
            material: this.job.item.category.dimension + ' ' + this.job.item.category.name,
            hoursAmount: this.hoursAmount,
            remainingQuantity: this.remainingQuantity,
            materialDeliveryDate: (this.job.materialDeliveryDate) ? this.job.materialDeliveryDate : "",
            deliveryDate: this.date,
            sheetsPerHour: this.job.item.sheetsPerHour,
            salesman: this.job.item.client.salesman.name,
            machineType: this.job.machineType,
            appropriateMachinesId: this.job.item.appropriateMachinesId
          };
        }

    }

    return Subjob;
});
